import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

// 在当前页面时候再次点击当前页面，会有警告！
// 原因：在路由中添加了相同的路由。
// 解决：重写路由的push方法
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

function load (view) {
  return resolve => import(`@/views/${view}.vue`)
}

const routes = [
  { path: '/login', component: load('Login'), hidden: true },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    hidden: true,
    children: [{ path: '/home', name: '首页', component: load('Home'), icon: 'home' }]
  },
  {
    path: '/booking',
    component: Layout,
    name: '大主宰预约',
    icon: 'user-astronaut',
    subMenu: true,
    meta: { breadcrumbLinkDisable: true },
    children: [
      { path: '/stat/amount', name: '预约统计', component: load('booking/StatAmount'), icon: 'chart-simple' },
      // { path: '/stat/inviteAlsoAnswer', name: '邀请且答题', component: load('booking/InviteAlsoAnswer'), icon: 'chart-column' },
      // { path: '/stat/invite/rank', name: '邀请数排名', component: load('booking/InviteRank'), icon: 'list-ol' },
      // { path: '/booking/award', name: '获奖记录', component: load('booking/AwardRecord'), icon: 'award' },
      { path: '/booking/success', name: '预约成功', component: load('booking/User'), icon: 'check' },
      { path: '/booking/fail', name: '预约失败', component: load('booking/BookingFail'), icon: 'xmark' }
      // { path: '/booking/invite', name: '邀请记录', component: load('booking/InviteRecord'), icon: 'people-pulling' },
      // { path: '/booking/question', name: '问卷题库', component: load('booking/Question'), icon: 'question' },
      // { path: '/booking/answer', name: '答题情况', component: load('booking/UserAnswer'), icon: 'pencil' },
      // { path: '/booking/wish', name: '许愿记录', component: load('booking/Wish'), icon: 'person-praying' }
    ]
  },
  {
    path: '/activity',
    component: Layout,
    name: '大主宰活动',
    icon: 'fa-cube',
    subMenu: true,
    meta: { breadcrumbLinkDisable: true },
    children: [
      // { path: '/activity/Card', name: '活动卡牌管理', component: load('activity/Card/index') },
      // { path: '/activity/grouping', name: '卡牌分组管理', component: load('activity/Grouping/index') },
      { path: '/activity/user', name: '用户集卡记录', component: load('activity/User/index') },
      { path: '/activity/user-search', name: '用户查询记录', component: load('activity/UserSearch/index') },
      { path: '/activity/issue-cards', name: '发放卡牌', component: load('booking/Activity') }
    ]
  },
  // {
  //   path: '/xblr',
  //   component: Layout,
  //   name: '新不良人官网',
  //   icon: 'globe',
  //   subMenu: true,
  //   meta: { breadcrumbLinkDisable: true },
  //   children: [
  //     { path: '/xblr/article', name: 'Article', component: load('xblr/Article'), icon: 'text-height' }
  //   ]
  // },
  // {
  //   path: '/sys',
  //   component: Layout,
  //   name: '预约系统设置',
  //   icon: 'gear',
  //   subMenu: true,
  //   meta: { breadcrumbLinkDisable: true },
  //   children: [
  //     { path: '/booking/activity', name: '活动配置', component: load('booking/Activity'), icon: 'dice-four' },
  //     { path: '/booking/game', name: '游戏配置', component: load('booking/Game'), icon: 'dice-d6' }
  //   ]
  // },
  {
    path: '/empty',
    component: Layout,
    redirect: '/empty',
    hidden: true,
    children: [{ path: '/empty', name: 'Empty', component: load('Empty'), icon: 'home' }]
  },
  // Always leave this last one
  { path: '*', component: load('404'), hidden: true }
]

const router = new VueRouter({
  routes
})

export default router
